import React from 'react'
import { Link } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import '../../assets/Scss/templates/comics.scss';

const Comics = () => {
  return (
    <Layout bodyClass="comics">
      <Seo title="AWRA COMICS" />
      <header className="container is-vcentered is-centered is-404">
        <h1 className='-title'>AWRA Comics</h1>
        <p>A series of comics for the whimsical at heart.</p>
      </header>
      <article className="container ">
        <section className="wrapper">
          <Link to="/comics/FGM" className="comic" >
            <StaticImage src="https://i0.wp.com/wp.awra-group.org/wp-content/uploads/2023/10/FIGE1.jpg?ssl=1" alt="FGM AWRA Comics" placeholder='blurred' transformOptions={{ fit: "fill", cropFocus: "attention" }} width={800} aspectRatio={1 / 1} />
          </Link>
          <Link to="/comics/space" className="comic" >
            <StaticImage src="https://i0.wp.com/wp.awra-group.org/wp-content/uploads/2023/10/IGE1.jpg?ssl=1" alt="FGM AWRA Comics" placeholder='blurred' transformOptions={{ fit: "fill", cropFocus: "attention" }} width={800} aspectRatio={1 / 1} />
          </Link>
        </section>
      </article>
    </Layout>
  )
}


export default Comics


